import { Injectable } from '@angular/core';
import { AuthenticatedUser } from '@models/authentication.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserManagerService {

  private readonly _user$ = new BehaviorSubject<AuthenticatedUser | null>(null);
  private _user: AuthenticatedUser | null = null;

  get user() {
    return this._user;
  }

  get listener() {
    return this._user$.asObservable();
  }

  setUser(user: AuthenticatedUser | null) {
    this._user = !user
      ? null
      : user

    this._user$.next(this._user);
  }

  editUser(user: Partial<AuthenticatedUser>) {
    this._user = {
      ...this._user,
      ...(user as AuthenticatedUser),
    };

    this._user$.next(this._user);
  }
}
