import { Injectable } from '@angular/core';
import { ToastConfig } from '@shared/types/toast.type';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private _toast$ = new Subject<ToastConfig>();

  emit(option: ToastConfig) {
    this._toast$.next(option);
  }

  get watch() {
    return this._toast$.asObservable();
  }
}
