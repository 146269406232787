import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storage: Storage;

  constructor() {
    this.storage = window.localStorage;
  }

  get(key: string): any {
    const data = this.storage.getItem(key);

    return data ? JSON.parse(data) : '';
  }

  set(key: string, data: any) {
    const jsonData = JSON.stringify(data);

    this.storage.setItem(key, jsonData);
  }

  remove(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}
